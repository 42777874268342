<template>
  <!--begin::Login forgot password form-->
  <div class="login-forgot">
    <div class="mb-10">
      <h3>忘记密码 ?</h3>
      <p class="opacity-60" style="width: 350px;">请通过手机号码来重置密码</p>
    </div>
    <form class="form" id="kt_login_forgot_form">
      <div role="alert" v-if="errors && errors.length > 0" v-bind:class="{ show: errors.length }" class="alert fade alert-danger" style="width: 350px;">
        <div class="alert-text" v-for="(error, i) in errors" :key="i">
          {{ error }}
        </div>
      </div>
      <div role="alert" v-if="msgs && msgs.length > 0" v-bind:class="{ show: msgs.length }" class="alert fade alert-success" style="width: 350px;">
        <div class="alert-text" v-for="(msg, i) in msgs" :key="i">
          {{ msg }}
        </div>
      </div>
      <div class="form-group">
        <div class="input-group mb-5">
          <input class="form-control h-auto text-white placeholder-white opacity-70 bg-dark-o-70 border-0 py-4 px-8" v-model="user.phone" type="text" placeholder="手机号码" name="phone" autocomplete="off" />
          <div class="input-group-append">
            <button type="button" class="btn btn-outline-secondary h-auto text-white placeholder-white opacity-70 bg-dark-o-70 border-0 py-4 px-8" v-on:click="validatePhone">发送</button>
          </div>
        </div>
      </div>
      <div class="form-group">
        <input class="form-control h-auto text-white placeholder-white opacity-70 bg-dark-o-70 border-0 py-4 px-8 mb-5" v-model="user.phoneCode" type="text" placeholder="短信验证码" name="valiCode" autocomplete="off" />
      </div>
      <div class="form-group">
        <input class="form-control h-auto text-white placeholder-white opacity-70 bg-dark-o-70 border-0 py-4 px-8 mb-5" v-model="user.password" type="password" placeholder="新密码" name="password" />
      </div>
      <div class="form-group">
        <input class="form-control h-auto text-white placeholder-white opacity-70 bg-dark-o-70 border-0 py-4 px-8 mb-5" v-model="user.repassword" type="password" placeholder="确认密码" name="cpassword" />
      </div>
      <div class="form-group">
        <button type="button" id="kt_login_forgot_submit" class="btn btn-pill btn-outline-white font-weight-bold opacity-90 px-15 py-3 m-2" v-on:click="editPsd">重置</button>
        <router-link to="/auth/login">
          <button type="button" id="kt_login_forgot_cancel" class="btn btn-pill btn-outline-white font-weight-bold opacity-70 px-15 py-3 m-2">取消</button>
        </router-link>
      </div>
    </form>
  </div>
  <!--end::Login forgot password form-->
</template>
<script>
export default {
  data() {
    return {
      user: {},
      errors: [],
      msgs: [],
    };
  },
  methods: {
    validatePhone: function() {
      this.errors = [];
      this.msgs = [];
      if (this.user) {
        if (this.user.phone && this.user.phone.length === 11) {
          // 请求后台获取验证码
          this.$axios.fetchPost('/hr/user/validate', this.user).then((data) => {
              if (data.data && data.data.res === "noDb") {
                this.errors.push("该手机号码未曾注册过，请前往注册");
              } else if (data.data && data.data.res === "exist") {
                this.errors.push("有多个该手机号码的用户，请联系管理员确定");
              } else if (data.data && data.data.res === "unuser") {
                this.errors.push("该手机号码未曾注册过，请前往注册");
              } else if (data.data && data.data.res === "success"){
                this.user.code = data.data.code;
                this.msgs.push("短信发送成功");
                return;
              }else {
                this.errors.push("请刷新重试");
              }
          }).catch(err => {
              console.log(err);
          })
        } else {
          this.errors.push("手机号码错误");
        }
      } else {
        this.errors.push("用户信息错误");
      }
    },
    editPsd: function() {
      this.errors = [];
      this.msgs = [];
      if (this.user) {
        if (!this.user.phone) {
          this.errors.push("请输入手机号码");
          return;
        }
        if (!this.user.phoneCode) {
          this.errors.push("请输入手机验证码");
          return;
        }
        if (!this.user.password) {
          this.errors.push("请填写密码");
          return;
        }
        if (!this.user.repassword) {
          this.errors.push("请确认密码");
          return;
        }
        if (this.user.password !== this.user.repassword) {
          this.errors.push("密码不一致");
          return;
        }
        this.$axios.fetchPost('/hr/user/editPwd', this.user).then((data) => {
            if (data.data === "noDb") {
              this.errors.push("该手机号码未曾注册过，请前往注册");
            } else if (data.data === "fail") {
              this.errors.push("短信验证码错误");
            } else if (data.data === "success") {
              this.msgs.push("密码修改成功,即将跳转登录界面！");
              setTimeout(this.toLogin,1500);
            } else {
              this.errors.push("请刷新重试");
            }
        }).catch(err => {
            console.log(err);
        });
      } else {
        this.errors.push("请填完整信息");
      }
    },
    toLogin: function (){
      this.$router.push("/auth/login");
    }
  }
}
</script>